import styled, { css } from 'styled-components';
import blueCheck from '../../../../static/icons/blue-checkmark.svg'
import darkBlueCheck from '../../../../static/icons/darkblue-checkmark.svg'
import lineCheck from '../../../../static/icons/check-line.svg'

export const UnorderedListCheckmark = styled.ul`
  padding-bottom: 24px;
  li {
    font-size: 16px;
    line-height: 24px;
    position: relative;
    padding: 8px 0 8px 34px;
    list-style: none;
    p {
      margin-bottom: -5px!important;
    }
    &:before {
      content: ' ';
      width: 24px;
      height: 24px;
      position: absolute;
      background-image: url(${blueCheck});
      ${props => props && props?.dark && css`
        background-image: url(${darkBlueCheck});
      `}
      ${props => props && props?.line && css`
        background-image: url(${lineCheck});
      `}
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      left: 0;
      top: 8px;
    }
  }
  br {
    display: none;
  }
`