import styled from 'styled-components';

export const OrderedListStandard = styled.ol`
  margin-left: 20px;
  padding: 24px 0px 2px 0px;
  li {
    padding: 5px 0px;
  }
  li::marker {
    font-weight: bold;
  }
  br {
    display: none;
  }
`