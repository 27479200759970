import React from 'react';
import { UnorderedListStandard } from './UnorderedListStandard';
import { UnorderedListCheckmark } from './UnorderedListCheckmark'
import { UnorderedListPersona } from './UnorderedListPersona';

export const UnorderedList = ({ type, children }) => {

  switch (type) {
    case "standard":
      return <UnorderedListStandard>{children}</UnorderedListStandard>;
    case "checkmark":
      return <UnorderedListCheckmark>{children}</UnorderedListCheckmark>
    case "dark-check":
      return <UnorderedListCheckmark dark>{children}</UnorderedListCheckmark>
    case "check-line":
      return <UnorderedListCheckmark line>{children}</UnorderedListCheckmark>
    case "persona":
      return <UnorderedListPersona>{children}</UnorderedListPersona>
    default:
      return <UnorderedListStandard>{children}</UnorderedListStandard>;
  }
}