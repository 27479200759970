import React from 'react';
import { OrderedListStandard } from './OrderedListStandard';

export const OrderedList = ({ type, children }) => {

  switch (type) {
    case "standard":
      return <OrderedListStandard>{children}</OrderedListStandard>;
    default: 
    return <OrderedListStandard>{children}</OrderedListStandard>;
  }
}