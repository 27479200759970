import styled from "styled-components";

export const PostContentWrapper = styled.div`
  white-space: pre-wrap;
  padding: 100px 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
  hr {
    border: 2px solid #002DC2;
    width: 48px;
    border-radius: 8px;
    margin: 40px 0 16px 0;
  }
  h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: -0.27%;
    color: #101828;
  }
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
    padding: 100px 15px;
  }
`
export const BreadCrumbFlex = styled.div`
  position: relative;
  z-index: 100;
  padding: 24px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  a {
    text-decoration: none;
    span {
      color: #002DC2
    }
  }
  img {
    padding: 0 10px;
    transform: scale(80%) translateY(2px);
  }
  span {
    color: ##002DC2;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: color 200ms ease;
    &:hover {
      color: #DDDDDD;
    }
  }
  @media (max-width: 576px) {
    position: relative;
    a span {
      color: #0000dd;
    }
    span {
      color: #000;
      &:hover {
        color: #00000099;
      }
    }
  }
`

export const ScrollBtnWrapper = styled.div`
  position: sticky;
  text-align: right;
  bottom: 20px;
  margin-top: -45px;
  transition: opacity 400ms ease, visibility 400ms ease;
  width: fit-content;
  float: right;
  button {
    transition: filter 200ms ease;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px!important;
    line-height: 10px!important;
    padding: 14px 15px !important;
    font-weight: 700;
    min-width: unset!important;
    height: unset!important;
  } 
  ${({ showScrollToTop }) =>
  `
    visibility: ${ showScrollToTop ? 'visible' : 'hidden' };
    opacity: ${ showScrollToTop ? '100%' : '0%' };
  `}
`

export const PostContentGrid = styled.div`
  margin-top: 24px;
  width: 100%;
  display: grid;
  grid-gap: 32px;
  overflow: visible;
  grid-template-columns: 2fr 1fr;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
  .social {
    background: #F2F4F7;
  }
  .right-col {
    margin-top: 40px;
    @media (max-width: 992px) {
      display: none;
    }
  }
`

export const BodyContentWrapper = styled.article`
  white-space: normal;
  color: #1B1B1B;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 25px;
  }
${({ theme }) =>
`
  font-family: ${theme.font.primary};
`}
`

export const FootnoteCaption = styled.div`
  padding-bottom: 32px;
  font-size: 12px;
  font-family: Roboto, 'Roboto', sans-serif; 
  color: #1B1B1B;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
`

export const IconImg = styled.div`
  img {
    &:last-child {
      display: none;
    }
  }
  @media (max-width: 576px) {
    img {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: block;
      }
    }
  }
`;