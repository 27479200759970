import styled from 'styled-components';

export const UnorderedListStandard = styled.ul`
  margin-left: 24px;
  padding-bottom: 24px;
  li {
    font-size: 16px;
    line-height: 24px;
    position: relative;
    p {
      margin-bottom: 5px!important;
    }
  }
  li::marker {
    font-size: 26px;
    color: #002DC2;
  }
  br {
    display: none;
  }
`