import loadable from "@loadable/component"
import React from 'react';

const QuoteBlockArticle = loadable(() => import('./QuoteBlockArticle'))
const QuoteBlockGreyBackground = loadable(() => import('./QuoteBlockGreyBackground'))
const QuoteBlockSlateFullWidthBar = loadable(() => import('./QuoteBlockSlateFullWidthBar'))
const QuoteBlockNaptimeFullWidthBar = loadable(() => import('./QuoteBlockNaptimeFullWidthBar'))
const QuoteBlockCosmic = loadable(() => import('./QuoteBlockFullWidthCosmic'))
const QuoteBlockBlue = loadable(() => import('./QuoteBlockFullWidthBlue'))
const QuoteBlockTrustCosmicWhite = loadable(() => import('./QuoteBlockTrustCosmicWhite'))
const QuoteBlockTrustCosmicElectric = loadable(() => import('./QuoteBlockTrustCosmicElectric'))
const QuoteBlockTrustSlate = loadable(() => import('./QuoteBlockTrustSlate'))
const QuoteBlockTrustBlue = loadable(() => import('./QuoteBlockTrustBlue'))

export const QuoteBlock = ({ quoteData }) => {

  const { type } = quoteData;

  switch (type) {
    case "article":
      return <QuoteBlockArticle quoteData={quoteData} />;
    case "grey-background":
      return <QuoteBlockGreyBackground quoteData={quoteData}/>;
    case "slate-full-width-bar":
      return <QuoteBlockSlateFullWidthBar quoteData={quoteData}/>;
    case "naptime-full-width-bar":
      return <QuoteBlockNaptimeFullWidthBar quoteData={quoteData}/>;
    case "cosmic-full-width":
      return <QuoteBlockCosmic quoteData={quoteData}/>;
    case "blue-full-width":
      return <QuoteBlockBlue quoteData={quoteData}/>;
    case "full-width-trust-cosmic-white":
      return <QuoteBlockTrustCosmicWhite quoteData={quoteData}/>;
    case "full-width-trust-cosmic-electric":
      return <QuoteBlockTrustCosmicElectric quoteData={quoteData}/>;
    case "full-width-trust-slate":
      return <QuoteBlockTrustSlate quoteData={quoteData}/>;
    case "full-width-trust-blue":
      return <QuoteBlockTrustBlue quoteData={quoteData}/>;
    default: 
      return <QuoteBlockArticle quoteData={quoteData} />;
  }
}