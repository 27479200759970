import styled from "styled-components"
import personaCheck from "../../../../static/icons/persona-check.svg"

export const UnorderedListPersona = styled.ul`
  padding-bottom: 24px;
  li {
    font-size: 16px;
    line-height: 24px;
    position: relative;
    padding: 8px 0 8px 28px;
    list-style: none;
    p {
      margin-bottom: -5px!important;
    }
    &:before {
      content: ' ';
      width: 14px;
      height: 14px;
      position: absolute;
      background-image: url(${personaCheck});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      left: 0;
      top: 12px;
    }
  }
  br {
    display: none;
  }
`