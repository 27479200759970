import React from 'react';
import { Link } from 'gatsby';
import {
  RelatedArticlesWrapper,
  Kicker,
  BorderLine,
  RelatedArticleListItem
} from "./styles/KnowledgeDetailRelated.styled"

export const KnowledgeDetailRelatedShareDesktop = ({ allPosts, relatedCategories }) => {
  const thisPostCategories = relatedCategories.map(category => category.title);

  const relatedArticles = allPosts.filter(post => thisPostCategories.includes(post.node.category[0].title || thisPostCategories.includes(post.node.category[1].title)));

  const displayedArticles = relatedArticles.slice(0, 5);

  return (
    <RelatedArticlesWrapper
      data-Related={KnowledgeDetailRelatedShareDesktop.displayName}
    >
      <Kicker>Related Articles</Kicker>
      <BorderLine />
      { displayedArticles && displayedArticles.map(article => {
        return (
          <Link style={{ textDecoration: 'none', color: '#1B1B1B' }} to={'/article/' + article?.node?.slug}>
            <RelatedArticleListItem>{article?.node?.title}</RelatedArticleListItem>
          </Link>
        )
      })}
    </RelatedArticlesWrapper>
  );
};

KnowledgeDetailRelatedShareDesktop.displayName = 'KnowledgeDetailRelatedShareDesktop';
