import React from "react"
import styled from "styled-components"

export const KnowledgeSocialShareRow = ({
  slug,
  backgroundColor,
  iconColor,
}) => {
  const socialIcons = [
    {
      iconName: "twitter",
      iconPath: "/icons/twitter-grey-circ.svg",
      link: `https://twitter.com/intent/tweet?url=https://www.workgrid.com/article/${slug}`,
    },
    {
      iconName: "facebook",
      iconPath: "/icons/fb-grey-circ.svg",
      link: `https://www.facebook.com/sharer/sharer.php?u=https://www.workgrid.com/article/${slug}`,
    },
    {
      iconName: "linkedin",
      iconPath: "/icons/linkedin-grey-circ.svg",
      link: `https://www.linkedin.com/shareArticle?mini=true&url=https://workgrid.gatsbyjs.io/${slug}`,
    },
    {
      iconName: "instagram",
      iconPath: "/icons/insta-grey-circ.svg",
      link: "https://www.instagram.com/workgridsoftware/",
    },
  ]
  const socialIconsBlue = [
    {
      iconName: "twitter",
      iconPath: "/icons/twitter-blue.svg",
      link: `https://twitter.com/intent/tweet?url=https://www.workgrid.com/${slug}`,
    },
    {
      iconName: "facebook",
      iconPath: "/icons/facebook-blue.svg",
      link: `https://www.facebook.com/sharer/sharer.php?u=https://www.workgrid.com/${slug}`,
    },
    {
      iconName: "linkedin",
      iconPath: "/icons/linkedin-blue.svg",
      link: `https://www.linkedin.com/shareArticle?mini=true&url=https://workgrid.gatsbyjs.io/${slug}`,
    },
    {
      iconName: "instagram",
      iconPath: "/icons/instagram-blue.svg",
      link: "https://www.instagram.com/workgridsoftware/",
    },
  ]

  // //replace linkedin with before prod///
  // const pathName = typeof window !== 'undefined' ? encodeURIComponent(window.location.host) : '';
  // https://www.linkedin.com/shareArticle?mini=true&url=${pathName}/blog/${slug}

  const handleSocialShare = url => {
    // eslint-disable-next-line
    const left = (screen.width - 570) / 2
    // eslint-disable-next-line
    const top = (screen.height - 570) / 2
    const params =
      "menubar=no,toolbar=no,status=no,width=570,height=570,top=" +
      top +
      ",left=" +
      left
    window.open(url, "NewWindow", params)
  }

  return (
    <CompWrapper
      data-comp={KnowledgeSocialShareRow.displayName}
      style={{ background: `${backgroundColor}` }}
    >
      <Title>Share</Title>
      <IconFlex>
        {!iconColor
          ? socialIcons.map(icon => (
              <>
                <div
                  onClick={() => handleSocialShare(icon?.link)}
                  onKeydown={() => handleSocialShare(icon?.link)}
                  role="button"
                  tabIndex={0}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper>
                    <img
                      src={icon.iconPath}
                      alt={`${icon?.iconName} share button`}
                    />
                  </IconWrapper>
                </div>
              </>
            ))
          : socialIconsBlue.map(icon => (
              <>
                <div
                  onClick={() => handleSocialShare(icon?.link)}
                  onKeydown={() => handleSocialShare(icon?.link)}
                  role="button"
                  tabIndex={0}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper>
                    <img
                      src={icon?.iconPath}
                      alt={`${icon?.iconName} share button`}
                    />
                  </IconWrapper>
                </div>
              </>
            ))}
      </IconFlex>
    </CompWrapper>
  )
}

KnowledgeSocialShareRow.displayName = "KnowledgeSocialShareRow"

const CompWrapper = styled.div`
  margin: 0 auto;
  border-radius: 6px;
  margin-top: 24px;
`

const Title = styled.div`
  padding: 32px 0px 16px 0px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #1b1b1b;
`

const IconFlex = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 32px;
`

const IconWrapper = styled.div`
  cursor: pointer;
  img {
    transition: filter 200ms ease;
  }
  img:hover {
    filter: brightness(85%);
  }
`
