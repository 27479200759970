import React, { useState, useEffect } from "react"
import { isBrowser } from "@utils/isBrowser"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import { KnowledgeDetailRelatedShareDesktop } from "../components/Knowledge/KnowledgeDetailRelatedShareDesktop"
import { KnowledgeSocialShareRow } from "../components/Knowledge/KnowledgeSocialShareRow"
import { TableOfContents } from "../components/Blog/BlogDetail/TableOfContents"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, Link } from "gatsby"
import { theme } from "@theme"
import { Button } from "@atoms"
import { postDetailResolveRichText } from "../utils/resolve-rich-text/blogDetailResolveRichText"
import ConversionPanelStandard from "@components/ConversionPanel/ConversionPanelStandard"

import {
  PostContentWrapper,
  BreadCrumbFlex,
  ScrollBtnWrapper,
  PostContentGrid,
  BodyContentWrapper,
  FootnoteCaption,
  IconImg,
} from "./styles/knowledgeDetail.styled"

const headingStyles = {
  padding: "8px 0px",
}

function KnowledgePostTemplate({ data }) {
  const [showScrollToTop, setShowScrollToTop] = useState(false)

  const {
    contentfulKnowledgeCenterPost,
    allPosts,
    contentfulComponentConversionPanel,
  } = data

  const postData = contentfulKnowledgeCenterPost
  const allKnowledgePosts = allPosts?.edges
  const conversionPanel = contentfulComponentConversionPanel
  const seo = contentfulKnowledgeCenterPost?.pageSeo

  useEffect(() => {
    if (!isBrowser()) {
      return null
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 500) {
        setShowScrollToTop(true)
      } else {
        setShowScrollToTop(false)
      }
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  function truncate(input) {
    if (input.length > 15) {
      return input.substring(0, 15) + "..."
    }
    return input
  }

  const allBlocksAndEntries = JSON.parse(postData?.body?.raw).content
  const tableOfContentsHeadings = allBlocksAndEntries.filter(
    block => block?.nodeType === "heading-2" || block?.nodeType === "heading-3"
  )

  return (
    <Layout>
      <Seo
        title={seo?.seoTitle || postData?.title}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url}
        imageAlt={seo?.graphImage?.description}
        metaTags={seo?.metaTags}
        article={seo?.article}
      />
      <PostContentWrapper id="blog_detail_content_wrapper">
        <BreadCrumbFlex>
          <Link href="/">
            <span>Home</span>
          </Link>
          <IconImg>
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
          </IconImg>
          <Link href="/resources/knowledge-center">
            <span>Knowledge Center</span>
          </Link>
          <IconImg>
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
          </IconImg>
          <span>{truncate(postData.title)}</span>
        </BreadCrumbFlex>
        <PostContentGrid>
          <div>
            {postData.tableOfContents && (
              <TableOfContents
                style={{ marginTop: "20px", marginBottom: "80px" }}
                tableOfContentsHeadings={tableOfContentsHeadings}
              />
            )}
            <BodyContentWrapper theme={theme}>
              {postDetailResolveRichText(postData, headingStyles)}
            </BodyContentWrapper>
            <div style={{ padding: "16px 0px" }} />
            {postData?.footnoteCaption?.raw && (
              <FootnoteCaption>
                <div>
                  {documentToReactComponents(
                    JSON.parse(postData?.footnoteCaption?.raw)
                  )}
                </div>
              </FootnoteCaption>
            )}
          </div>
          <div className="right-col">
            <KnowledgeDetailRelatedShareDesktop
              allPosts={allKnowledgePosts}
              relatedCategories={postData?.category}
            />
            <KnowledgeSocialShareRow
              slug={postData?.slug}
              backgroundColor={"#F2F4F7"}
              iconColor={"blue"}
            />
          </div>
        </PostContentGrid>
        <ScrollBtnWrapper
          showScrollToTop={showScrollToTop}
          onClick={scrollToTop}
        >
          <Button ctaVariant="secondary">Back To Top</Button>
        </ScrollBtnWrapper>
      </PostContentWrapper>
      <ConversionPanelStandard component={conversionPanel} />
    </Layout>
  )
}

export default KnowledgePostTemplate

export const KowledgePostQuery = graphql`
  query KnowledgeDetailQuery($id: String) {
    contentfulKnowledgeCenterPost(id: { eq: $id }) {
      id
      title
      slug
      pageSeo {
        graphImage {
          description
          title
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
        metaTags
        article
      }
      description {
        description
      }
      category {
        title
        id
      }
      body {
        raw
        references {
          ... on ContentfulComponentAlternateQuoteBlock {
            contentful_id
            __typename
            id
            type
            internal {
              type
            }
            quote {
              quote
            }
            author {
              role
              headshot {
                gatsbyImageData
                file {
                  url
                }
                description
              }
              fullName
              company {
                name
                logo {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
            }
          }
          ... on ContentfulComponentVideo {
            id
            caption {
              raw
            }
            alignCaption
            contentful_id
            autoplay
            title
            videoUrl
            videoFile {
              title
              description
              gatsbyImageData
              file {
                url
              }
            }
            internal {
              type
            }
          }
          ... on ContentfulComponentImage {
            id
            internalLink
            url
            caption {
              raw
            }
            alignCaption
            contentful_id
            description
            internal {
              type
            }
            primaryImage {
              gatsbyImageData
              id
              title
            }
          }
        }
      }
    }
    contentfulComponentConversionPanel(
      id: { eq: "d1fa0451-62d1-504a-95d5-173585e967ed" }
    ) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      noTopPadding
      noBottomPadding
      subheading {
        raw
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      type
    }
    contentfulComponentFaqAccordion(
      id: { eq: "f106a9de-8e72-58fd-81b7-856c489592a5" }
    ) {
      __typename
      contentful_id
      subhead
      internalName
      heading
      noTopPadding
      noBottomPadding
      faQs {
        answer {
          raw
        }
        question
      }
    }
    contentfulComponentCarousel(
      id: { eq: "fbe3a779-5de3-51ae-9470-44bffbec967d" }
    ) {
      __typename
      id
      type
      subheading {
        subheading
      }
      subheadingTest {
        raw
      }
      heading
      headingSize
      autoplay
      noTopPadding
      noBottomPadding
      carouselItems {
        ... on ContentfulComponentGeneric {
          id
          align
          background
          callsToAction {
            variant
            type
            link
            label
            id
            internalName
            internalLink
            contentful_id
          }
          heading
          headingSize
          subheading {
            raw
          }
          icon {
            gatsbyImageData
            file {
              url
            }
            title
            description
            contentful_id
          }
          featuredImage {
            file {
              url
            }
            title
            description
            contentful_id
          }
        }
      }
    }
    allPosts: allContentfulKnowledgeCenterPost(
      filter: { id: { nin: [$id, "70ae2990-d224-583c-9d7b-b280584d7d3b"] } }
    ) {
      edges {
        node {
          id
          title
          slug
          description {
            description
          }
          category {
            backgroundColor
            title
            id
          }
          body {
            raw
          }
          image {
            file {
              url
            }
            title
            description
          }
        }
      }
    }
  }
`
