import styled from 'styled-components';

export const RelatedArticlesWrapper = styled.div`
  font-family: Roboto;
  padding: 24px;
  border: 1px solid #F0F0F0;
  border-radius: 9px;
  width: 100%;
`

export const Kicker = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #4E4E4E;
`

export const BorderLine = styled.div`
  margin: 16px 0px; 
  width: 100%;
  height: 1px;
  background: #F0F0F0;
`

export const RelatedArticleListItem = styled.div`
  padding: 14px 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #0E0E0E;
  transition: color 150ms ease;
  &:hover {
    color: #525252;
  }
`